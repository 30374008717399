export const fakeData = [
    {
        "id": 1,
        "name": "BFSI",
        "message": "Aon plc, Bofl Holding, Inc., Broadridge Financial Solutions, Inc., Caribbean Financial Group, Experian, Fidelity National Financial, Inc., FINRA, FolioDynamix, FXCM, GE Capital, Microfinance Information Exchange, Inc., Penn National Insurance, Roosevelt Management Company, Titan Lenders Corp., Warwick Resource Group, LLC"
    },
    {
        "id": 2,
        "name": "Entertainment & Media",
        "message": "A&E Television Networks, LLC, Artnet, Axiom Communications, Inc., Seven Peaks"
    },
    {
        "id": 3,
        "name": "Healthcare & Pharmaceuticals",
        "message": "ALR Technologies, Inc., American Academy of Pediatrics, Cardinal Health, Inc., Childrens Healthcare of Atlanta, Cigna Corporation, HemaSource, Inc., Horizon Blue Cross Blue Shield of New Jersey, ICON Plc, INC Research, Inc., Indigenus Network, LLC, Integra LifeSciences Corporation, IOD Incorporated, Lancaster General Hospital, MultiPlan, Inc., Noven Pharmaceuticals, Inc., Thoratec Corporation, TriZetto Corporation, UnitedHealth Group Information Services Pvt. Ltd."
    },
    {
        "id": 4,
        "name": "Manufacturing",
        "message": "CPI Card Group, Inc., Microsemi Corporation, Packsize International LLC, Panduit Corp., Sieling and Jones, Inc."
    },
    {
        "id": 5,
        "name": "Publishing",
        "message": "Elsevier, The McGraw-Hill Companies, W.H. Freeman, Wolters Kluwer"
    },
    {
        "id": 6,
        "name": "Transportation & Distribution",
        "message": "AEXGroup, American Automobile Association of Northern California, Nevada & Utah, BNSF Railway, CAE, Canadian Pacific Railway, Limited, CaroTrans International, Inc., Copa Airlines, Incorporated., Finnish Aviation Academy"
    },
    {
        "id": 7,
        "name": "Telecommunications",
        "message": "Alcatel-Lucent, Amdocs Limited, Ericsson, Verizon Communications, Inc."
    },
    {
        "id": 8,
        "name": "Retail",
        "message": "Atlas Embroidery & Screen Printing, Nations Photo Lab, Nu Image Marketing, Provo Craft & Novelty, Inc., Tupperware Corporation"
    },
    {
        "id": 9,
        "name": "Information Technology",
        "message": "Accenture plc, APTARE, Inc., Autodesk, Inc., Axway, Callidus Software, Inc., Cognizant, Computer Science Corporation, Dell, Inc., EarthSoft, Easy Soft, LLC, ExtraTeam, HCL America, Inc., Headstrong, Infosys BPO Limited, Keane, Inc., Kronos Incorporated, Luxul Wireless, Inc., Oracle, Parker Hannifin Corporation, Quistor, Solix Technologies, Sumaria Networks, LLC, SunGard, Tech Mahindra, Turbo Data Systems, Inc, UpSearch LLC, Virtustream, Inc., Wipro Limited"
    },
    {
        "id": 10,
        "name": "Construction",
        "message": "Alexandria Real Estate Equities, Inc., Fulton Homes Corp., Precision Concrete Cutting, Skanska USA Inc"
    }
];
