import React from "react";
import styles from './SMediaServices.module.css';
import img1 from '../../assets/sMediaService/main.png';
import img2 from '../../assets/sMediaService/middle1.png';
import img3 from '../../assets/sMediaService/bottom1.png';

const SMediaServices = () =>{
    return (
        <>
            <div>
                {/* Top  */}
                <div className="row mt-5 mx-0 flex-column-reverse flex-md-row">
                    <div className="col-md-6 px-0">
                        <div className={styles.topContain}>
                            <p className={`${styles.headTop}`}>Cloud <span style={{color:'#ff4c0a'}}>Development</span></p>
                            <p className={styles.identityTop}>Cloud Marketing</p>
                            <p className={styles.content}>Planning or ready to build next generation, cloud-enabled software applications? 
0-Downtime can help with cost-effective conception, planning, design and development. We help you with designing applications engineered to make the best of the cloud to meet your requirements.
</p>
                        </div>
                    </div>
                    <div className="col-md-6 px-0">
                        <img src={img1} alt="" className={styles.img1}/>
                    </div>
                </div>

                {/* Middle  */}
                <div className="container">
                    <div className={`row ${styles.contain} flex-column-reverse flex-md-row`}>
                        <div className={`col-md-6 ${styles.middleContain}`}>
                            <p className={styles.heading}>Migration Strategy & Implementation </p>
                            <p className={styles.text}>Migrating existing systems and applications or cloud-enabling new ones? 
0-Downtime Cloud Solutions can help you plan, cloud-enable, migrate and deploy them using secure cloud frameworks with auto-scaling and a faster time to market. We help you create a detailed assessment of the solutions you want to migrate. Then, we assist you in breaking them down into smaller isolated components that will work more effectively hosted on the cloud than the physical solution in your data center.
</p>
                        </div>
                        <div className="col-md-6">
                            <img src={img2} alt="" className={styles.img2}/>
                        </div>
                    </div>
                </div>

                {/* Bottom  */}
                <div className="container">
                    <div className={`row ${styles.contain}`}>
                        <div className="col-md-6">
                            <img src={img3} alt="" className={styles.img2}/>
                        </div>
                        <div className={`col-md-6 ${styles.bottomContain}`}>
                            <p className={styles.heading1}>Custom Approach</p>
                            <p className={styles.text1}>Our team will help you establish a presence on both Facebook and Twitter with custom designed graphical elements that will make a lasting impression on all who visit your page. Combine this visibility with the ability to link directly to your website and you have a recipe for major exposure and traffic.</p>
                        </div>
                        
                    </div>
                </div>

            </div>
        </>
    )
}
export default SMediaServices;