import img1 from '../../../assets/aboutUs/bottom1.png';
import img2 from '../../../assets/aboutUs/bottom2.png'

const fakeData = [
    {
        id: 1,
        heading: "What types of opportunities do you staff?",
        img: img1,
        content: 'We provide assistance with any type and any level of IT position. We have direct placement, contract to hire and contract only positions ranging from Developers, Network Engineers, Quality Assurance Analysts, Database Administrators, Systems Administrators and Project Managers. We also staff Help Desk, Operations, Technical Writers/Trainers, and Technical Architects. We also work in the Telecommunications, Engineering, and Web Design sectors.'
    },
    {
        id: 2,
        heading: 'Do you offer benefits to your employees?',
        img: img2,
        content: 'Yes. We tailor our benefit and compensation packages to meet the needs of the individual. All of our benefits are "ala carte", so you are able to create a benefit package that is the right fit for you and your family.'
    },
]
export default fakeData;