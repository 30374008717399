import React from "react";
import styles from './Footer.module.css';
import fb from '../../assets/home/facebook.png';
import linkdin from '../../assets/home/linkdin.png';
import insta from '../../assets/home/insta.png';
import headerLogo from '../../assets/headerLogo.jpeg';

const Footer = () => {
    return (
        <>
            <div style={{ backgroundColor: '#2b2b2b', paddingTop: '89px', paddingBottom: '90px' }}>
                <div className="container" >
                    <div className="row">
                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <div style={{ display: 'flex', justifyContent: "center" }}>
                                <img src={headerLogo} alt="" style={{ height: "40px", width: "50px", margin: "0px 10px 0px 0px" }} />
                                <p className={styles.logo}>GlobalITCon
                                </p>
                            </div>
                            <p className={styles.text}>GlobalITCon is a trusted and highly accredited organisation that provides IT consultancy services and technology solutions.</p>
                            <div className={styles.social_logo}>
                                <img src={fb} alt="fb" className={styles.img} />
                                <img src={linkdin} alt="in" className={styles.img} />
                                <img src={insta} alt="insta" className={styles.img} />
                            </div>
                        </div>

                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <p className={styles.head}>Head Office</p>
                            <p className={styles.text}>103 Geet Mohini</p>
                            <p className={styles.text}>Phase 6 </p>
                            <p className={styles.text}>Bhopal, MP Pin: 462041</p>
                            <p className={styles.text}>Pin: 462041</p>
                            <p className={styles.text}>info@GLOBALITCON.com</p>
                        </div>
                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>

                        <p className={styles.head}>U.S. Branch Office</p>

<p className={styles.text}>1201  N  Orange St.</p>
<p className={styles.text}> Suite#7045 Wilmington</p>
<p className={styles.text}>DE 19801</p>

                        </div>

                      

                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <p className={styles.head}>India Branch Office</p>
                            <p className={styles.text}>196 SFS Hauzkhas Apartment</p>
                            <p className={styles.text}>Hauzkhas New Delhi</p>
                            <p className={styles.text}>110016</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;