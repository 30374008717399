import React from "react";
import styles from './Middle.module.css';
import AWSImg from '../../../assets/AWS.png';
import cloudImg from '../../../assets/cloud.png';
import migrationImg from '../../../assets/migration.png';
import data from "./data";
const Middle = () =>{
    return(
        <div style={{backgroundColor: '#f8f8f8'}} className='overflow-hidden'>
            <div className={`container ${styles.contain}`}>
                <p data-aos='fade-down-right' data-aos-offset="170" className={styles.mainHead}>GLOBALITCON</p>
                <p data-aos='fade-up-left' data-aos-offset="170" className={styles.mainContent}>GLOBALITCON Inc is a fast growing, end-to-end information technology services and solutions provider working with leading Global 2000 organizations from the Banking & Financial Services, Healthcare, Telecommunications & Media, Technology, Energy </p>

                <div className="row">
                
                    {
                        data.map((item =>{return(

                            <div data-aos='fade-left' className="col-md-4">
                        <div className={styles.box}>
                            <img src={AWSImg} alt=""/>
                            <p className={styles.head}>{item.heading}</p>
                            <p className={styles.content}>{item.content}</p>
                        </div>
                    </div>
                    )
                        }))
                    }
                    
                </div>
            </div>
        </div>
    )
}
export default Middle;