import React from "react";
import styles from './ChoosingUs.module.css';
import experience from '../../assets/home/experience.png';
import communication from '../../assets/home/communication.png';
import comfort from '../../assets/home/comfort.png';

const ChoosingUs = () =>{
    return(
        <div style={{backgroundColor: '#fff1e5'}}>
            <div className="container overflow-hidden">
                <p data-aos='slide-right' data-aos-offset="220" className={styles.heading}>Top three the reason why you should choosing us.</p>
                <p data-aos='slide-left' className={styles.sub_text}>GLOBALITCON delivers by meeting all of your business’s unique needs and always strives to exceed your expectations along the way.</p>
                <div className={`row ${styles.sub}`}>
                    <div data-aos='fade-right' className="col-md-4">
                        <img src={experience} alt="experience"/>
                        <p className={styles.logo_head}>Vigilant Project Management</p>
                        <p className={styles.logo_text}>With strict adherence to project management processes throughout your project's lifecycle, we ensure timely deliverables and optimal results.</p>
                    </div>

                    <div data-aos='fade' className="col-md-4">
                        <img src={communication} alt="experience"/>
                        <p className={styles.logo_head}>Experience</p>
                        <p className={styles.logo_text}>
                        Our team brings years of industry experience to the table, providing you with insights, solutions, and strategies honed through successful project deliveries. We leverage our expertise to address challenges and maximize opportunities, delivering results that exceed expectations.</p>
                    </div>

                    <div data-aos='fade-left' className="col-md-4">
                        <img src={comfort} alt="experience"/>
                        <p className={styles.logo_head}>Multidisciplinary Team of Experts</p>
                        <p className={styles.logo_text}>Our group of highly-skilled, creative and technically adept individuals includes graphic artists, multimedia developers, programmers and more – representing the best-of-class talent the industry has to offer.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChoosingUs;