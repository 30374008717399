import React from "react";
import styles from './Top.module.css';
import img1 from '../../../assets/aboutUs/img1.png';
import img2 from '../../../assets/aboutUs/img2.png';

const Top =()=>{
    return(
        <div className={`container overflow-hidden`}>
            <p data-aos='slide-right' className={styles.heading}>About Us</p>
            <p data-aos='slide-left' className={styles.subHeading}>Web Development Service</p>
            <p data-aos='fade-up' data-aos-offset='80' className={styles.content}>GLOBALITCON Inc is a fast growing, end-to-end information technology services and solutions provider working with leading Global 2000 organizations from the Banking & Financial Services, Healthcare, Telecommunications & Media, Technology, Energy & Utilities and Manufacturing & Retail domains. GLOBALITCON Inc delivers highly responsive and innovative solutions that help clients align their IT strategy with business goals to address the most important IT needs through a combination of consulting solutions, proprietary methodologies, assets based services and a Collaborative Engagement Model.</p>
        
            <div className={`row ${styles.contain} flex-column-reverse flex-md-row`}>
                <div data-aos='slide-right' className={`col-md-6`}>
                    <p className={styles.about}>About Us</p>
                    <p className={styles.creativity}>We Do with Creativity</p>
                    <p className={styles.contn}>
                    At GLOBALITCON Inc, our team has multiple years of experience helping our clients find the right talent for their needs. We are locally owned, HQ at Virginia, and focus almost exclusively on the Information Technology market. We provide direct placement, contract to hire, and contract only services, and provide a great deal of flexibility within our services. We partner with our customers to learn their environment, processes, and goals, so that we can serve them better. Our goal is to help find the right talent in order to meet business objectives. We are part of your solution! 
                    </p>
                </div>
                <div data-aos='slide-left' data-aos-offset='50' className={`col-md-6 ${styles.imgContain}`}>
                    <img src={img1} alt="" className={styles.img1}/>
                </div>
            </div>
            <div data-aos='fade-up' className='row' style={{textAlign:'left'}}>
                <div className={`col-md-6`}> 
                    <img src={img2} alt="" className={styles.img2}/>
                </div>
            </div>
        </div>
    )
}

export default Top;