import AWSImg from '../../assets/AWS.png';
import cloudImg from '../../assets/cloud.png';
import migrationImg from '../../assets/migration.png';
const data = [
    {
        id: 1,
        heading: "Devops",
        content: "Cloud Development, GPC, AWS, Azure",
        to: '/',
        icon: cloudImg,
    },
    {
        id: 2,
        heading: "Mobile Application",
        content: "Android/IOS Hybrid/Native Applications , Flutter, React Native, AI App",
        to: '/',
        icon: migrationImg,
    },{
        id: 3,
        heading: 'Web Design',
        content: "Angular js , React.js, Mean stack , Word press",
        to: '/',
        icon: AWSImg,
    }
]

export default data;