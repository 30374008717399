import React from "react";
import styles from "./ServiceHome.module.css";

const ServiceHome = (props) => {
    console.log("aditya",props.content)
    return (
        <div className={`${styles.box} user-select-none`}>
            <img src={props.icon} alt="" className={styles.img} />
            <p className={styles.heading}>{props.heading}</p>
            <p className={styles.text}>{props.content}</p>
        </div>
    );
};
export default ServiceHome;
