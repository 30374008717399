import React from "react";
import styles from './AboutUsHome.module.css';
import img1 from '../../assets/home/about1.png';
import img2 from '../../assets/home/about2.png';
import img3 from '../../assets/home/about3.png';

const AboutUsHome = () =>{
    return(
        <>
            <div className={`container ${styles.contain}`}>
                <div className="row overflow-hidden">
                    <div className="col-md-6">
                        <p data-aos="slide-right" className={styles.head}>ABOUT US</p>
                        <p data-aos="slide-left" className={styles.heading}>We have a great team in terms of technology</p>
                        <div data-aos="zoom-in">
                            <p className={styles.content}>GLOBALITCON Inc is a fast growing, end-to-end information technology services and solutions provider working with leading Global 2000 organizations from the Banking & Financial Services, Healthcare, Telecommunications & Media, Technology, Energy & Utilities and Manufacturing & Retail domains. GLOBALITCON Inc delivers highly responsive and innovative solutions that help clients align their IT strategy with business goals to address the most important IT needs through a combination of consulting solutions, proprietary methodologies, assets based services and a Collaborative Engagement Model.</p>
                        </div>
                        <div data-aos='zoom-in' style={{textAlign: 'left'}}>
                        </div>
                        
                    </div>

                    <div className="col-md-6 mt-5 overflow-hidden">
                        <div className={styles.row}>
                            <div className={`${styles.column} ${styles.col1}`}>
                                <img data-aos="zoom-in-right" src={img1} alt=""/>
                            </div>
                            <div className={styles.column}>
                                <img data-aos="zoom-in-left" src={img2} style={{width:'110%'}} alt=""/>
                                <img data-aos="zoom-in-left" src={img3} alt=""/>
                            </div>
    
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AboutUsHome;